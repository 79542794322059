

import { ref, defineComponent } from 'vue'
import {Dose, DosingTool} from '@/model/dose';
import Tooltip from '@/components/ui/Tooltip.vue';

const ADJUSTMENTS = {
  '2-5ml-dropper': [[17.74, 0.5], [38.49, 1], [58.87, 1.5], [79.62, 2], [100, 2.5]],
  '5ml-dropper': [[18.28, 1], [38.81, 2], [58.96, 3], [79.4, 4], [100, 5]],
  '0-6ml-dropper': [[47.8, 0.3], [100, 0.6]],
  '0-8ml-dropper': [[46, 0.4], [100, 0.8]],
  '1ml-dropper-2': [[34.3, 0.25], [56.37, 0.5], [78.37, 0.75], [100, 1]],
  '1ml-dropper': [[53.7, 0.5], [100, 1]],
  'acetaminophen-infant-syringe': [[19.72, 0], [43.38, 1.25], [62.25, 2.5], [81.13, 3.75], [100, 5]],
  'ibuprofen-infant-syringe': [[20.83, 0], [44.08, 0.625], [71.43, 1.25], [100, 1.875]],
  'cup': [[21.75, 2.5], [39.4, 5], [54.7, 7.5], [70.5, 10], [86.5, 12.5], [100, 15]],
  '30ml-cup': [[9.29, 2.5], [20.8, 5], [32.3, 7.5], [43.8, 10], [55.3, 12.5], [66.35, 15], [77.5, 20], [89.3, 25], [100, 30]],
  '15ml-syringe': [[13.07, 0],[18.81, 1],[100,15]],
  '10ml-syringe': [[18.24, 0],[26.4, 1],[100,10]],
  '10ml-syringe-2': [[18.24, 0],[26.72, 1],[100,10]],
  '5ml-syringe': [[19.73, 0],[25.6, 0.4],[35.49, 1],[100,5]],
  '5ml-syringe-2': [[19.73, 0],[26.48, 0.5],[34.65, 1],[100,5]],
  '5ml-syringe-3': [[19.73, 0],[25.92, 0.5],[34.1, 1],[83.10,4],[100,5]],
  '3ml-syringe': [[12.80, 0],[27.23, 0.5],[100,3]],
  '1ml-syringe': [[11.23, 0],[20.37, 0.1],[100,1]],
  '1ml-syringe-2': [[11.23, 0],[20.37, 0.1],[100,1]],
  '10ml-dosing-spoon': [[50.22, 5], [100, 10]],
};

export default defineComponent({
  setup() {
    const dragwrapper = ref<HTMLElement>()

    return {
      dragwrapper
    }
  },
  props: {
    dose: {
      type: Object as () => Dose,
      required: true
    },
    tool: {
      type: Object as () => DosingTool,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hideToolDrag: {
      type: Boolean,
      default: false
    },
    demo: {
      type: Boolean,
      default: false
    },
    demoPlaying: {
      type: Boolean,
      default: false
    },
    reset: {
      type: Boolean,
      default: false
    },
    showInstructions: {
      type: Boolean,
      default: false
    },
    instructionsOverride: {
      type: String,
      default: ''
    },
    prefill: {
      type: [Number, String],
      default: 0
    }

  },
  components: {
    Tooltip
  },
  computed: {
    toolWithTooltipOnRight() {
      return true;
        //return ['cup','30ml-cup'].indexOf(this.tool.tool) !== -1;
    },
    fillStyle() {
      let percentFilled = (this.filledRaw / this.tool.properties.size) * 100;
      return {
        height: percentFilled + '%'
      }
    },
    dragStyle() {
      return {
        bottom: this.dragPercentage + '%'
      }
    },
    // filled() {
    //   //console.log(ADJUSTMENTS[this.tool.tool]);
    //   if (ADJUSTMENTS[this.tool.tool]) {
    //     let i =0;
    //     while (ADJUSTMENTS[this.tool.tool][i][0] < this.dragPercentage && i < ADJUSTMENTS[this.tool.tool].length - 1) i++;
    //     const ratio = this.dragPercentage / ADJUSTMENTS[this.tool.tool][i][0];
    //     const coeficient = (1 + ((ADJUSTMENTS[this.tool.tool][i][1] - 1) * ratio));
    //     //console.log(ratio, coeficient);
    //
    //     return coeficient * this.filledRaw;
    //
    //   }
    //   return this.filledRaw
    // },
    filled() {
      if (ADJUSTMENTS[this.tool.tool]) {
        let i =0;
        while (ADJUSTMENTS[this.tool.tool][i][0] < this.dragPercentage && i < ADJUSTMENTS[this.tool.tool].length - 1) i++;

        let ratioDrag = 0;

        if (i === 0) {
          ratioDrag = this.dragPercentage / ADJUSTMENTS[this.tool.tool][i][0];
          return ratioDrag * ADJUSTMENTS[this.tool.tool][i][1];
        }
        else {

          let dragDiff = ADJUSTMENTS[this.tool.tool][i][0] - ADJUSTMENTS[this.tool.tool][i-1][0];

          return ADJUSTMENTS[this.tool.tool][i-1][1] + (this.dragPercentage - ADJUSTMENTS[this.tool.tool][i-1][0]) * (ADJUSTMENTS[this.tool.tool][i][1] - ADJUSTMENTS[this.tool.tool][i-1][1]) / dragDiff;
        }
      }
      return this.filledRaw
    },
    instructionsText() {
      if (!this.instructionsOverride) {
        return this.$t('PRACTICE.TOOL_TOOLTIP')
      }
      return this.instructionsOverride;
    }
  },
  watch: {
    demoPlaying(value, oldValue) {
      //console.log(value);
      if (value && !oldValue) {
        this.playDemo()
      }
    },
    reset(value, oldValue) {
      //console.log(value);
      if (value) {
        this.filledRaw = 0;
        this.dragPercentage = 0;
      }
    },
    showInstructions(value, oldValue) {
      if (value) {
        this.showInstructionsTooltip = true;
      }
      else {
        this.showInstructionsTooltip = false;
      }
    },
  },
  data() {
    return {
      filledRaw: 0,
      dragging: false,
      dragPosition: {
        x: 0,
        y: 0
      },
      dragPercentage: 0,
      demoTimeout: 0,
      showInstructionsTooltip: false,
      showGiveTooltip: false,
      shownGiveTooltip: false,
      demoFills: new Array<number>(),
      demoCurrentFill: 0
    }
  },
  mounted() {
    if (this.prefill) {
      this.dragPercentage = this.computeDragPercentageForFill(this.prefill);
      //this.filledRaw = parseFloat(this.prefill.toString());
      this.filledRaw = this.dragPercentage / 100 * this.tool.properties.size;
    }
    if (this.showInstructions) {
      if (!this.demo) {
        setTimeout(() => {
          this.showInstructionsTooltip = true;
        }, 500)
        setTimeout(() => {
          this.showInstructionsTooltip = false;
        }, 20000)
      }
    }
    if (this.demoPlaying) {
      this.playDemo();
    }
  },
  methods: {
    computeDragPercentageForFill(fill) {
      if (ADJUSTMENTS[this.tool.tool]) {
        let i =0;
        while (ADJUSTMENTS[this.tool.tool][i][1] < fill && i < ADJUSTMENTS[this.tool.tool].length - 1) i++;

        if (i === 0) {
          const ratioFill = fill / ADJUSTMENTS[this.tool.tool][i][1];
          return ratioFill * ADJUSTMENTS[this.tool.tool][i][0];
        }
        else {

          let fillDiff = ADJUSTMENTS[this.tool.tool][i][1] - ADJUSTMENTS[this.tool.tool][i-1][1];

          return ADJUSTMENTS[this.tool.tool][i-1][0] + (fill - ADJUSTMENTS[this.tool.tool][i-1][1]) * (ADJUSTMENTS[this.tool.tool][i][0] - ADJUSTMENTS[this.tool.tool][i-1][0]) / fillDiff;
        }
      }
      return fill / this.tool.properties.size * 100;
    },
    getPosition(e) {
      if (e.originalEvent)
        e = e.originalEvent;
      let x, y;
      if(e.type == 'touchstart' || e.type == 'touchmove' || e.type == 'touchend' || e.type == 'touchcancel'){
        const touch = e.touches[0] || e.changedTouches[0];
        x = touch.pageX;
        y = touch.pageY;
      } else if (e.type == 'mousedown' || e.type == 'mouseup' || e.type == 'mousemove' || e.type == 'mouseover'|| e.type=='mouseout' || e.type=='mouseenter' || e.type=='mouseleave') {
        x = e.clientX;
        y = e.clientY;
      }
      return {x, y}
    },
    onDragStart(e) {
      if (!this.disabled) {
        this.dragging = true;
        if (!this.demo) {
          this.showInstructionsTooltip = false;
        }
        this.dragPosition = this.getPosition(e);
      }
    },
    onDrag(e) {
      if (this.dragging) {
        if (!this.demo) {
          this.showInstructionsTooltip = false;
        }
        //console.log(e);
        if (e.originalEvent)
          e = e.originalEvent;
        e.preventDefault();
        e.stopPropagation();
        let position = this.getPosition(e);
        let deltaY = position.y - this.dragPosition.y;
        if (this.dragwrapper) {
          let percentageMoved = (deltaY / this.dragwrapper.clientHeight) * 100;
          this.dragPercentage -= percentageMoved;
          if (this.dragPercentage > 110) this.dragPercentage = 110;
          if (this.dragPercentage < 0) this.dragPercentage = 0;
          this.filledRaw = this.dragPercentage / 100 * this.tool.properties.size;
        }
        this.dragPosition = position;
      }
    },
    onDragEnd(e) {
      if (!this.disabled) {
        this.dragging = false;
        this.$emit('endDrag');

        if (this.showInstructions && !this.shownGiveTooltip) {
          if (!this.demo) {
            this.shownGiveTooltip = true;
            setTimeout(() => {
              this.showGiveTooltip = true;
            }, 500)
            setTimeout(() => {
              this.showGiveTooltip = false;
            }, 20000)
          }
        }

      }
    },
    giveFillManual() {
      if (!this.disabled) {
        this.giveFill();
      }
    },
    giveFill() {
      this.showGiveTooltip = false;
      this.$emit('fillSelected', this.filled);

      this.filledRaw = 0;
      this.dragPercentage = 0;
    },
    playDemoStep(): void {
      this.dragPercentage++;
      this.dragging = true;

      this.filledRaw = this.dragPercentage / 100 * this.tool.properties.size;

      if (this.dragPercentage < this.demoFills[this.demoCurrentFill]) {
        this.demoTimeout = window.setTimeout(() => {
          this.playDemoStep();
        }, 20);
      }
      else {
        this.dragPercentage = this.demoFills[this.demoCurrentFill];
        this.filledRaw = this.dragPercentage / 100 * this.tool.properties.size;

        //this.showGiveTooltip = true;

        this.dragging = false;

        this.$emit('finishedDemo');

      }
    },
    playDemo(): void {

      this.showInstructionsTooltip = false;

      let numberOfFills = Math.ceil(parseFloat(this.dose.dose) / this.tool.properties.size);
      //console.log(numberOfFills);
      for (var i = 0; i < numberOfFills; i++) {
        if (i !== numberOfFills - 1) this.demoFills.push(100);
        else {
          let rest = parseFloat(this.dose.dose) - (i *  this.tool.properties.size);
          //console.log(rest);
          let restPercentage = this.computeDragPercentageForFill(rest);

          this.demoFills.push(restPercentage);
        }
      }
      //console.log(this.demoFills);

      this.demoCurrentFill = 0;

      this.filledRaw = 0;
      this.dragPercentage = 0;
      if (this.demoTimeout) {
        window.clearTimeout(this.demoTimeout);
      }
      this.$emit('demoPlaying')
      this.demoTimeout = window.setTimeout(() => {
        this.playDemoStep();
      }, 20);
    }
  }
})
