
export default {
    computed: {
    },
    methods: {
        minDigitsForDose(dose) {
            let digits = 0;
            let amount = Math.floor(parseFloat(dose) * 1000) / 1000;
            while (digits < 3 && Math.floor(amount) != amount) {
                digits++;
                amount = parseFloat((amount * 10).toFixed(3));
            }
            return digits;
        },
    }
}
