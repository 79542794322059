
import {defineComponent, ref} from 'vue'
import {SET_PRACTICE_MEDICINE, SET_WATCH_DEMO_MEDICINE} from '@/store';
import {DosingTool} from '@/model/dose';
import {ParticipantMedicine} from '@/model/participant-data';
import PracticeDosingTool from '@/components/views/medicines/practice/PracticeDosingTool.vue';
import eventBus from '@/plugins/eventBus';
import globalMixin from "@/global-mixin";

export default defineComponent({
  setup() {
    const scrollElement = ref<HTMLElement>()

    return {
      scrollElement
    }
  },
  mixins: [globalMixin],
  props: {
  },
  components: {
    PracticeDosingTool
  },
  data() {
    return {
      medicine: {} as ParticipantMedicine,
      loading: false,
      selectedTool: {} as DosingTool,
      fills: [] as any[],
      fillPlaying: null as number | null,
      error: false,
      ready: false,
      lockBodyScroll: true,
      step: 1,
      loadingSave: false
    }
  },
  computed: {
    watchDemoMedicine() {
      return this.$store.getters.watchDemoMedicine;
    },
    digitsNeeded(): number {
      let digits = 1;
      let amount = parseFloat(this.medicine.dose) * 10;
      while (digits < 3 && Math.floor(amount) != amount) {
        digits++;
        amount = amount * 10;
      }
      return digits;
    },
    doseFormatted():string {
      //return '-';
      return parseFloat(this.medicine.dose).toFixed(this.minDigitsForDose(this.medicine.dose));
    },
    numberOfFills(): number {
      return Math.ceil(parseFloat(this.medicine.dose) / this.selectedTool.properties.size);
    },
    dosingToolIsCup(): boolean {
      return this.selectedTool?.properties?.category === 'cup';
    }
  },
  mounted() {
    this.loading = true;
    this.$store.dispatch('getMedicine', this.watchDemoMedicine).then((response) => {
      this.loading = false;
      this.medicine = response;
      this.selectedTool = this.medicine.selected_tool;
      this.setFills();
      setTimeout(() => {
        this.ready = true;
      }, 500);
    }).catch(() => {
      this.loading = false;
      this.error = true;
    })
  },
  methods: {

    scrollTop() {
      setTimeout(() => {
        if (this.scrollElement) {
          this.scrollElement.scrollTop = 0;
        }
      });
    },

    scrollBottom() {
      setTimeout(() => {
        if (this.scrollElement) {
          this.scrollElement.scrollTop = this.scrollElement.scrollHeight;
        }
      });
    },
    setFills() {
      let fills: any[] = [];
      let dose = parseFloat(this.medicine.dose);
      let size = this.selectedTool.properties.size;
      let numberOfFills = Math.ceil(dose / size);
      for (let i = 0; i < numberOfFills; i++) {
        let fill = {
          dosage: {
            dose: parseFloat(Math.min(size, dose).toString()).toFixed(this.digitsNeeded),
            playing: false
          }
        };
        dose = dose - size;
        fills.push(fill);
      }
      this.fills = fills;
    },
    watchDemo() {
      this.step++;
      setTimeout(() => {
        this.fillPlaying = 0;
      }, 1000);
    },
    endDemo() {
      this.$store.commit(SET_PRACTICE_MEDICINE, this.watchDemoMedicine);
      this.close();
    },
    close() {
      this.$store.commit(SET_WATCH_DEMO_MEDICINE, null);
    },
    onFinishStepDemo() {
      setTimeout(() => {
          if (this.fillPlaying !== null && this.fillPlaying < this.fills.length - 1) {
            this.fillPlaying++;
            setTimeout(() => {
              this.scrollBottom();
            });
          }
          else {
            this.step++;
            this.saveViewedDemo();
            this.scrollBottom();
          }
      }, 500)
    },
    saveViewedDemo() {
      this.loadingSave = true;
      this.$store.dispatch('saveViewedDemoForMedicine', {
        medicineID: this.medicine.id
      })
          .then((response) => {
            this.loadingSave = false;
            eventBus.$emit(eventBus.MEDICINE_UPDATE, response);
          }).catch((e) => {
        this.loadingSave = false;
        console.log(e)
      })
    },
    onEndDrag() {
      console.log('onEndDrag');
    },
    onFillSelected() {
      console.log('onFillSelected');
    }
  }
})
