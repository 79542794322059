

import { defineComponent } from 'vue'
import Error from '@/components/Error.vue';
import Loading from '@/components/layout/Loading.vue';
import PracticePopup from '@/components/views/medicines/practice/PracticePopup.vue';
import PickToolPopup from '@/components/views/medicines/practice/PickToolPopup.vue';
import WatchDemoPopup from '@/components/views/medicines/practice/WatchDemoPopup.vue';
import {SET_TOKEN, SET_DEBUG, SET_LOADING} from "@/store";
import {SET_LAYOUT_CONFIG} from "@/store/layout_module";
import { useCookies } from "vue3-cookies";

export default defineComponent({
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  props: {
  },
  components: {
    Loading,
    Error,
    PracticePopup,
    PickToolPopup,
    WatchDemoPopup
  },
  data() {
    return {
      error: false
    }
  },
  computed: {
    seenWelcome() {
      return this.$store.getters.seenWelcome;
    },
    loading() {
      return this.$store.getters.loading;
    },
    languageLoaded() {
      return this.$store.getters.languageLoaded;
    },
    pickToolMedicineSeleced() {
      return this.$store.getters.pickToolMedicineSelected;
    },
    practiceMedicineSelected() {
      return this.$store.getters.practiceMedicineSelected;
    },
    watchDemoMedicineSelected() {
      return this.$store.getters.watchDemoMedicineSelected;
    }
  },
  beforeRouteEnter(to, from, next) {
    const token = to.params.token;
    const debug = to.query.debug !== undefined;
    //const { cookies } = useCookies();
    const noTracking = to.query.notracking !== undefined;// || cookies.get('PROMPT_TRACKING_DISABLED');
    next((vm) => {
      vm.fetchData(token, debug, noTracking);
    })
  },
  created() {
    if (this.$route.name === 'dashboard') {
      this.$router.push({name: 'dashboard.welcome'})
    }
  },
  methods: {
    fetchData(token: string | string[], debug = false, noTracking = false): void {
      this.$store.commit(SET_LOADING, true);
      this.$store.commit('layout/' + SET_LAYOUT_CONFIG, {
        showPageTitle: false,
        showFooterNav: false
      });

      let params = {
      }
      if (debug) {
        params = Object.assign(params, {
          debug: 1
        });
      }
      if (noTracking) {
        params = Object.assign(params, {
          noTracking: 1
        });
      }

      this.$store.commit(SET_DEBUG, debug);
      this.$store.commit(SET_TOKEN, token);

      if (this.$route.meta && this.$route.meta.pageTitleConfig) {
        this.$store.commit('layout/' + SET_LAYOUT_CONFIG, {
          showPageTitle: this.$route.meta.pageTitleConfig.showPageTitle,
          showFooterNav: this.$route.meta.pageTitleConfig.showFooterNav,
        });
      }

      this.$store.dispatch('getParticipantData', params).then(() => {
        this.$store.commit(SET_LOADING, false);
        /*
                if (typeof this.$ga !== 'undefined') {
                  if (response.data && response.data.data && response.data.data.goals && response.data.data.goals.length) {
                    this.$ga.event('Open Dashboard - Current Goal', response.data.data.goals[0].goalKey, token);
                  }
                }
        */
      }).catch(error => {
        console.log(error);
        this.$store.commit(SET_LOADING, false);
        this.error = true;
      });

    }
  }
})

