import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "tooltip-element" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, { name: _ctx.transitionName }, {
      default: _withCtx(() => [
        (_ctx.visible)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["custom-tooltip", {'top': _ctx.orientationTop, 'bottom': _ctx.orientationBottom, 'rights-side': _ctx.rightSide, 'center': _ctx.center}])
            }, [
              _renderSlot(_ctx.$slots, "tooltip"),
              (_ctx.hasDismiss)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    class: "dismiss",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dismiss()))
                  }, _toDisplayString(_ctx.dismissLabel), 1))
                : _createCommentVNode("", true)
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 8, ["name"]),
    _renderSlot(_ctx.$slots, "default")
  ]))
}