
import {defineComponent, ref} from 'vue'
import Popup from '@/components/ui/Popup.vue';
import {SET_PICK_TOOL_MEDICINE} from '@/store';
import {Dose, DosingTool, DosingToolGroup} from '@/model/dose';
import { Carousel, Slide } from 'vue3-carousel';
import {ParticipantMedicine} from '@/model/participant-data';
import eventBus from '@/plugins/eventBus';

export default defineComponent({
  setup() {
    const carousel = ref<Carousel>(null)

    return {
      carousel
    }
  },
  props: {
    medicine: {
      type: Object as () => ParticipantMedicine,
      required: true
    }
  },
  components: {
    Popup,
    Carousel,
    Slide,
  },
  computed: {
    recommendedSelected() {
      return this.selectedToolsGroup.key === this.medicine.recommended_tools[0].key;
    },
    recommendedTool() {
      if (this.medicine.recommended_tools.length) {
        return this.firstToolOfGroup(this.medicine.recommended_tools[0]);
      }
      return null;
    },
    selectedToolIndex() {
      if (this.selectedToolsGroup && this.medicine.other_tools.length) {
        return this.medicine.other_tools.findIndex((el: DosingToolGroup) => {
          return el.key === this.selectedToolsGroup.key;
        })
      }
      return -1;
    },
    firstOfSelectedTools() {
      if (this.selectedToolsGroup && this.selectedToolsGroup.tools.length) {
        return this.firstToolOfGroup(this.selectedToolsGroup);
      }
      return null;
    }
  },
  data() {
    return {
      step: 1,
      noToolWarningVisible: false,
      selectedToolsGroup: {} as DosingToolGroup,
      selectedVariant: {} as DosingTool,
      initialSlide: 0,
      loading: false,
      settings: {
        itemsToShow: 2.5,
        snapAlign: 'center',
        //modelValue: 10
      },
      breakpoints: {
        // 320 and up
        320: {
          itemsToShow: 2.5
        },
        // 567 and up
        567: {
          itemsToShow: 3.5
        },
      },
    }
  },
  mounted() {
      if (this.medicine.recommended_tools.length) {
        this.selectRecommended();
      }
      else if (this.medicine.other_tools.length) {
        this.selectOtherTool(this.medicine.other_tools[0]);
      }
  },
  methods: {
    confirmSelection() {
      if (this.selectedToolsGroup && this.selectedToolsGroup.tools.length === 1) {
        this.saveSelection(this.selectedToolsGroup.tools[0]);
      }
      else if (this.selectedToolsGroup && this.selectedToolsGroup.tools.length > 1) {
        this.step = 2;
        this.$emit('selectSize');
      }
    },
    finalConfirmSelection() {
      this.saveSelection(this.selectedVariant);
    },
    saveSelection(tool) {
      this.loading = true;
      this.$store.dispatch('saveToolForMedicine', {
                   medicineID: this.medicine.id,
                   tool: tool})
      .then((response) => {
          this.loading = false;
          this.$emit('selectTool', tool);
          eventBus.$emit(eventBus.MEDICINE_UPDATE, response);
      }).catch((e) => {
        this.loading = false;
        console.log(e)
      })
    },
    isSelected(toolsGroup: DosingToolGroup) {
      if (!this.selectedToolsGroup) return false;
      return this.selectedToolsGroup.key === toolsGroup.key;
    },
    isSelectedVariant(variant: DosingTool) {
      return this.selectedVariant && this.selectedVariant.id === variant.id;
    },
    noTools() {
      this.noToolWarningVisible = true;
    },
    onGetHelp() {
      this.$store.commit(SET_PICK_TOOL_MEDICINE, null);
    },
    selectRecommended() {
      this.selectedToolsGroup = this.medicine.recommended_tools[0];
      this.selectDefaultVariant();
    },
    scrollToSelectedTool() {
      if (this.selectedToolIndex !== -1)
        this.carousel.slideTo(this.selectedToolIndex);
    },
    selectOtherTool(toolsGroup: DosingToolGroup) {
      this.selectedToolsGroup = toolsGroup;
      this.scrollToSelectedTool();
      this.selectDefaultVariant();
    },
    selectDefaultVariant() {
      if (this.selectedToolsGroup && this.selectedToolsGroup.tools.length) {
        this.selectedVariant = this.firstToolOfGroup(this.selectedToolsGroup);
      }
      else this.selectedVariant = {} as DosingTool;
    },
    selectVariant(variant: DosingTool) {
      this.selectedVariant = variant;
    },
    backToStep1() {
      this.step = 1;
      this.$emit('selectSize');
    },
    firstToolOfGroup(toolsGroup) {
      if (toolsGroup.tools.length > 1) {
        if (toolsGroup.key === 'syringe_5') {
          if (Math.floor(parseFloat(this.medicine.dose) / 0.5) === Math.ceil(parseFloat(this.medicine.dose) / 0.5)) {
            //if dose is a multiple of 0.5, look for the one with .5 markings ('5ml-syringe-3')
            const tool = toolsGroup.tools.find((el: DosingTool) => {
              return el.tool === '5ml-syringe-3';
            })
            if (tool) return tool;
          }
          else {
            const tool = toolsGroup.tools.find((el: DosingTool) => {
              return el.tool === '5ml-syringe';
            })
            if (tool) return tool;
          }
        }
        if (toolsGroup.key === 'syringe_10') {
          if (Math.floor(parseFloat(this.medicine.dose) / 0.5) === Math.ceil(parseFloat(this.medicine.dose) / 0.5)) {
            //if dose is a multiple of 0.5, look for the one with .5 markings ('10ml-syringe')
            const tool = toolsGroup.tools.find((el: DosingTool) => {
              return el.tool === '10ml-syringe';
            })
            if (tool) return tool;
          }
          else {
            const tool = toolsGroup.tools.find((el: DosingTool) => {
              return el.tool === '10ml-syringe-2';
            })
            if (tool) return tool;
          }
        }
      }
      if (toolsGroup.tools.length > 0) {
        return toolsGroup.tools[0];
      }
      return null;
    },
  }
})
