import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Error = _resolveComponent("Error")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_PracticePopup = _resolveComponent("PracticePopup")!
  const _component_PickToolPopup = _resolveComponent("PickToolPopup")!
  const _component_WatchDemoPopup = _resolveComponent("WatchDemoPopup")!

  return (_openBlock(), _createElementBlock("main", null, [
    _createElementVNode("div", null, [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.error && !_ctx.loading)
        ? (_openBlock(), _createBlock(_component_Error, { key: 1 }))
        : _createCommentVNode("", true),
      (!_ctx.loading && !_ctx.error && _ctx.languageLoaded)
        ? (_openBlock(), _createBlock(_component_router_view, { key: 2 }))
        : _createCommentVNode("", true),
      (_ctx.practiceMedicineSelected)
        ? (_openBlock(), _createBlock(_component_PracticePopup, { key: 3 }))
        : _createCommentVNode("", true),
      (_ctx.pickToolMedicineSeleced)
        ? (_openBlock(), _createBlock(_component_PickToolPopup, { key: 4 }))
        : _createCommentVNode("", true),
      (_ctx.watchDemoMedicineSelected)
        ? (_openBlock(), _createBlock(_component_WatchDemoPopup, { key: 5 }))
        : _createCommentVNode("", true)
    ])
  ]))
}