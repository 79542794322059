
import { defineComponent } from 'vue'
import {DosingTool} from '@/model/dose';
import Tooltip from '@/components/ui/Tooltip.vue';
import PracticeDosingTool from '@/components/views/medicines/practice/PracticeDosingTool.vue';
// import Popup from '@/components/ui/Popup.vue';`
import {ParticipantMedicine} from '@/model/participant-data';
import { useCookies } from "vue3-cookies";
import eventBus from '@/plugins/eventBus';
import globalMixin from "@/global-mixin";

export default defineComponent({
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  mixins: [globalMixin],
  props: {
    medicine: {
      type: Object as () => ParticipantMedicine,
      required: true
    },
    tool: {
      type: Object as () => DosingTool,
      required: true
    }
  },
  components: {
    PracticeDosingTool,
    Tooltip
    // Popup
  },
  computed: {
    digitsNeeded() {
      let digits = 1;
      let amount = parseFloat(parseFloat(this.medicine.dose).toFixed(3)) * 10;
      while (digits < 3 && Math.floor(amount) != amount) {
        digits++;
        amount = amount * 10;
      }
      if (digits < 2 && this.tool.properties.size <= 1) digits = 2;
      return digits;
    },
    doseFormatted() {
      return parseFloat(this.medicine.dose).toFixed(this.minDigitsForDose(this.medicine.dose));
    },
    amountGivenFormatted() {
      return this.amountGiven.toFixed(this.minDigitsForDose(this.amountGiven));
    },
    resultMessageTitle() {
      if (this.result < 0) return 'PRACTICE.RESULT.NOT_ENOUGH.TITLE';
      if (this.result > 0) return 'PRACTICE.RESULT.TOO_MUCH.TITLE';
      return 'PRACTICE.RESULT.GOOD.TITLE';
    },
    resultMessage() {
      if (this.result < 0) return 'PRACTICE.RESULT.NOT_ENOUGH.INFO';
      if (this.result > 0) return 'PRACTICE.RESULT.TOO_MUCH.INFO';
      return 'PRACTICE.RESULT.GOOD.INFO';
    },
    resultButton() {
      if (this.result < 0) return 'PRACTICE.RESULT.NOT_ENOUGH.BUTTON';
      if (this.result > 0) return 'PRACTICE.RESULT.TOO_MUCH.BUTTON';
      return 'PRACTICE.RESULT.GOOD.BUTTON';
    },
    resultDifference() {
      const doseNeeded = parseFloat(this.medicine.dose);
      if (this.result === 0) {
        return this.amountGiven.toFixed(this.minDigitsForDose(this.amountGiven));
      }
      const diff = Math.abs(doseNeeded - this.amountGiven);
      return diff.toFixed(this.minDigitsForDose(diff));
    },
    dosingToolIsCup(): boolean {
      return this.tool?.properties?.category === 'cup';
    }
  },
  data() {
    return {
      amountGiven: 0,
      timesGiven: 0,
      showIntro: true,
      showInstructions: false,
      showGiveInstructions: false,
      practicing: true,
      isFinished: false,
      result: 0,
      fills: [] as any[]
    }
  },
  mounted() {
    // this.showInstructions = true;
    // this.showGiveInstructions = true;

    let hasSeenInstructions = this.cookies.get('PRACTICE_SEEN_TOOL_INSTRUCTIONS_' + this.medicine.id);
    if (!hasSeenInstructions) {
      this.showInstructions = true;
      this.cookies.set('PRACTICE_SEEN_TOOL_INSTRUCTIONS_' + this.medicine.id, '1', 60 * 60 * 24);
    }
  },
  methods: {
    finish() {
      const doseNeeded = parseFloat(this.medicine.dose);
      let allowedMargin = doseNeeded * 0.05;
      //if (this.digitsNeeded >= 2) allowedMargin = 0.01;
      /*
      if (this.tool.properties.size <= 1) allowedMargin = 0.01;
      if (this.tool.properties.size >= 15) allowedMargin = 0.5;
      if (this.tool.properties.size >= 20) allowedMargin = 1;
       */
      if (this.amountGiven > doseNeeded - allowedMargin && this.amountGiven < doseNeeded + allowedMargin) this.result = 0;
      else if (this.amountGiven > doseNeeded + allowedMargin) this.result = 1;
      else if (this.amountGiven < doseNeeded + allowedMargin) this.result = -1;
      this.isFinished = true;
      if (this.result === 0) {
        this.savePracticed();
      }
      this.$emit('finishedGiving');
    },
    dismissFinish() {
      if (this.result === 0) {
        this.$emit('finished');
      }
      else {
        this.amountGiven = 0;
        this.timesGiven = 0;
        this.isFinished = false;
        this.fills = [];
        this.giveMore();
      }
    },
    closeIntro() {
      this.showIntro = false;
      this.$emit('practicing');
    },
    giveMore() {
      this.$emit('practicing');
      this.practicing = true;
      this.showInstructions = false;
      this.showGiveInstructions = false;
    },
    savePracticed() {
      this.$store.dispatch('savePracticedForMedicine', {
        medicineID: this.medicine.id
      })
          .then((response) => {
            eventBus.$emit(eventBus.MEDICINE_UPDATE, response);
          }).catch((e) => {
        console.log(e)
      })
    },
    onFillSelected(fill: number) {
      this.$emit('endPracticing');
      this.timesGiven++;
      let fillCleaned = fill.toFixed(this.digitsNeeded)
      this.fills.push({
        dose: fillCleaned
      });
      this.amountGiven += parseFloat(fillCleaned);
      this.amountGiven = parseFloat(this.amountGiven.toFixed(this.digitsNeeded));
      this.practicing = false;

      setTimeout(() => {
        let hasSeenGiveInstructions = this.cookies.get('PRACTICE_SEEN_GIVE_INSTRUCTIONS_' + this.medicine.id);
        if (!hasSeenGiveInstructions) {
          this.showGiveInstructions = true;
          this.cookies.set('PRACTICE_SEEN_GIVE_INSTRUCTIONS_' + this.medicine.id, '1', 60 * 60 * 24);
        }
      }, 500);
    },
    onEndDrag() {
      this.$emit('endDrag');
    }
  }
})
