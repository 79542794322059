

import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    orientation: {
      type: String,
      default: 'top'
    },
    visible: {
      type: Boolean,
      default: false
    },
    rightSide: {
      type: Boolean,
      default: false
    },
    center: {
      type: Boolean,
      default: false
    },
    hasDismiss: {
      type: Boolean,
      default: false
    },
    dismissLabel: {
      type: String,
      default: 'Got it!'
    }
  },
  components: {
  },
  computed: {
    orientationTop() {
      return this.orientation === 'top'
    },
    orientationBottom() {
      return this.orientation === 'bottom'
    },
    transitionName() {
      if (this.orientationBottom) return 'fadeup';
      return 'fadedown'
    }
  },
  methods: {
    dismiss() {
      this.$emit('dismiss');
    }
  }
})
