import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "position-absolute d-block text-danger debug-info text-center"
}
const _hoisted_2 = { class: "fill-wrapper" }
const _hoisted_3 = {
  class: "fill-wrapper handle-wrapper",
  ref: "dragwrapper"
}
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 0,
  class: "icon-slider"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "give-button-wrapper" }
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _directive_touch = _resolveDirective("touch")!
  const _directive_scroll_lock = _resolveDirective("scroll-lock")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["dosing-tool-wrapper flex-grow-1", 'tool-' + _ctx.tool.tool])
  }, [
    (_ctx.debug)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.dragPercentage.toFixed(2)) + "% -> " + _toDisplayString(_ctx.filledRaw.toFixed(4)) + " mL --> " + _toDisplayString(_ctx.filled.toFixed(4)) + " mL", 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["dosing-tool", {'centered': _ctx.demo}])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: "fill",
          style: _normalizeStyle(_ctx.fillStyle)
        }, null, 4)
      ]),
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
        _withDirectives((_openBlock(), _createElementBlock("a", {
          class: "drag-handle",
          style: _normalizeStyle(_ctx.dragStyle)
        }, [
          _createVNode(_component_Tooltip, {
            orientation: 'top',
            "has-dismiss": true,
            "dismiss-label": _ctx.$t('PRACTICE.DISMISS_TOOLTIP'),
            visible: _ctx.showInstructionsTooltip,
            onDismiss: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showInstructionsTooltip = false)),
            "right-side": _ctx.toolWithTooltipOnRight
          }, {
            tooltip: _withCtx(() => [
              _createElementVNode("div", { innerHTML: _ctx.instructionsText }, null, 8, _hoisted_4)
            ]),
            default: _withCtx(() => [
              (!_ctx.hideToolDrag || !_ctx.disabled)
                ? (_openBlock(), _createElementBlock("i", _hoisted_5))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["dismiss-label", "visible", "right-side"])
        ], 4)), [
          [_directive_touch, _ctx.onDragStart, "press"],
          [_directive_touch, _ctx.onDragEnd, "release"],
          [_directive_touch, _ctx.onDrag, "drag"]
        ])
      ])), [
        [_directive_scroll_lock, _ctx.dragging]
      ]),
      _createElementVNode("img", {
        src: '/images/tools/' + _ctx.tool.tool + '.png'
      }, null, 8, _hoisted_6)
    ], 2),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_Tooltip, {
        orientation: 'top',
        visible: _ctx.showGiveTooltip,
        "right-side": true,
        "has-dismiss": true,
        "dismiss-label": _ctx.$t('PRACTICE.DISMISS_TOOLTIP'),
        onDismiss: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showGiveTooltip = false))
      }, {
        tooltip: _withCtx(() => [
          _createElementVNode("div", {
            innerHTML: _ctx.$t('PRACTICE.GIVE_TO_DANIEL.TOOLTIP')
          }, null, 8, _hoisted_8)
        ]),
        default: _withCtx(() => [
          (!_ctx.demo)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                class: _normalizeClass(["btn btn-md py-2 rounded-pill btn-primary d-block give-button", {'disabled': _ctx.filled <= 0 || _ctx.dragging || _ctx.disabled }]),
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.giveFillManual()))
              }, _toDisplayString(_ctx.$t('PRACTICE.GIVE_TO_DANIEL')), 3))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["visible", "dismiss-label"])
    ])
  ], 2))
}