
import {defineComponent, ref} from 'vue'
import PickTool from '@/components/views/medicines/practice/PickTool.vue';
import {SET_PICK_TOOL_MEDICINE, SET_WATCH_DEMO_MEDICINE} from '@/store';
import {DosingTool} from '@/model/dose';
import eventBus from '@/plugins/eventBus';

export default defineComponent({
  setup() {
    const scrollElement = ref<HTMLElement>()

    return {
      scrollElement
    }
  },
  props: {
  },
  components: {
    PickTool
  },
  data() {
    return {
      loading: false,
      selectedTool: {} as DosingTool,
      medicine: null,
      error: false,
      ready: false,
      lockBodyScroll: true
    }
  },
  computed: {
    pickToolMedicine() {
      return this.$store.getters.pickToolMedicine;
    },
  },
  mounted() {
    this.loading = true;
    this.$store.dispatch('getMedicine', this.pickToolMedicine).then((response) => {
      this.loading = false;
      this.medicine = response;
      setTimeout(() => {
        this.ready = true;
      }, 500);
    }).catch(() => {
      this.loading = false;
      this.error = true;
    })
  },
  methods: {
    onToolSelected(tool) {
      this.selectedTool = tool;
      this.scrollTop();
    },
    scrollTop() {
      setTimeout(() => {
        if (this.scrollElement) {
          this.scrollElement.scrollTop = 0;
        }
      });
    },
    close() {
      this.$store.commit(SET_PICK_TOOL_MEDICINE, null);
    },
    onEndDragTool() {
      console.log('endDrag')
      this.lockBodyScroll = false;
      setTimeout(() => {
        this.lockBodyScroll = true;
      },100)
    },
    gotoWatchDemo() {
      this.$store.commit(SET_WATCH_DEMO_MEDICINE, this.pickToolMedicine);
      this.close();
    }
  }
})
