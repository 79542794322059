
import {defineComponent, ref} from 'vue'
import PracticeGiving from '@/components/views/medicines/practice/PracticeGiving.vue';
import {SET_PRACTICE_MEDICINE} from '@/store';
import {DosingTool} from '@/model/dose';

const STEP_PICK = 'pick';
const STEP_VIDEO = 'video';
const STEP_PRACTICE = 'practice';

export default defineComponent({
  setup() {
    const scrollElement = ref<HTMLElement>()

    return {
      scrollElement
    }
  },
  props: {
  },
  components: {
    PracticeGiving
  },
  data() {
    return {
      step: STEP_PRACTICE,
      loading: false,
      selectedTool: {} as DosingTool,
      medicine: null,
      error: false,
      ready: false,
      lockBodyScroll: true,
      practicing: true
    }
  },
  computed: {
    practiceMedicine() {
      return this.$store.getters.practiceMedicine;
    },
    isStepPick(): boolean {
      return this.step === STEP_PICK
    },
    isStepVideo(): boolean {
      return this.step === STEP_VIDEO
    },
    isStepPractice(): boolean {
      return this.step === STEP_PRACTICE
    },
  },
  mounted() {
    this.loading = true;
    this.$store.dispatch('getMedicine', this.practiceMedicine).then((response) => {
      this.loading = false;
      this.medicine = response;
      this.selectedTool = response.selected_tool;
      setTimeout(() => {
        this.ready = true;
      }, 500);
    }).catch(() => {
      this.loading = false;
      this.error = true;
    })
  },
  methods: {
    scrollTop() {
      setTimeout(() => {
        if (this.scrollElement) {
          this.scrollElement.scrollTop = 0;
        }
      });
    },
    scrollBottom() {
      setTimeout(() => {
        if (this.scrollElement) {
          this.scrollElement.scrollTop = this.scrollElement.scrollHeight;
        }
      });
    },
    close() {
      this.$store.commit(SET_PRACTICE_MEDICINE, null);
    },
    onEndDragTool() {
      console.log('endDrag')
      this.lockBodyScroll = false;
      setTimeout(() => {
        this.lockBodyScroll = true;
      },100)
    },
    onStartPracticing() {
      this.practicing = true;
    },
    onEndPracticing() {
      this.practicing = false;
    }
  }
})
